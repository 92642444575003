const adminRoute = [
    'index',
    'stat.classes',
    'stat.subjects',
    'user.profile',
    'users.guardians',
    'users.students',
    'classes.primary',
    'classes.junior',
    'classes.senior',
    'classes.topics',
    'officers.manage',
    'themes',
    'settings.fees'
]

export default adminRoute