<template>
  <div>
      <main class="main-content">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-12">
                    <!-- Start: error page -->
                    <div class="min-vh-100 content-center">
                        <div class="error-page text-center">
                            <img src="/img/svg/404.svg" alt="404" class="svg">
                            <div class="error-page__title">404</div>
                            <h5 class="fw-500">Sorry! the page you are looking for doesn't exist.</h5>
                            <div class="content-center mt-30">
                                <a class="btn btn-primary btn-default btn-squared px-30 text-white" @click="navigate">Return {{routeAction.label}}</a>
                                
                            </div>
                            <div>
                                <!-- <a href="" class="pt10" @click="$router.back()">Go Back</a> -->
                            </div>
                        </div>
                    </div>
                    <!-- End: error page -->
                </div>
            </div>
        </div>
    </main>
  </div>
</template>

<script>
// import { publicClient } from '../plugins/restclient'
export default {
    name: 'PageNotFound',
    layout: "plain",
    data(){
        return{
            routeAction: {
                label: '',
                path:''
            }
        }
    },
     metaInfo: {
    title: 'Greater Favour',
    titleTemplate: '%s | Not Found',
  },
    created(){
        const onboard = localStorage.getItem('onboarding')
        if(onboard){
            this.routeAction.label = 'to Login'
            this.routeAction.path = '/login'
        }else{
            this.routeAction.label = 'Home'
        }
        console.log(onboard)
    },
    methods:{
        navigate(){
            if(this.routeAction.path == '/login'){
                this.$store.dispatch('logout')
            }else{
                this.$router.push('/')
            }
        }
    }
}
</script>

<style>

</style>